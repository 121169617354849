/* eslint-disable class-methods-use-this */
/**
 * Kovax
 *
 * @author Robuust
 */

import { ApplicationController, useDebounce } from 'stimulus-use';
import * as Turbo from '@hotwired/turbo';

const { mapkit } = window;

/**
 * Controller for dealers.
 */
export default class extends ApplicationController {
  /**
   * @return {Array}
   */
  static targets = ['query', 'loader', 'list', 'dealer', 'map'];

  /**
  * @return {Array}
  */
  static classes = ['active'];

  /**
   * @return {Object}
   */
  static values = {
    url: String,
  };

  /**
   * @return {Array}
   */
  static debounces = ['search'];

  /**
   * @var {Map}
   */
  map;

  /**
   * @var {Array}
   */
  annotations = [];

  /**
   * Initialize map.
   */
  initialize() {
    mapkit.init({
      authorizationCallback(done) {
        done(import.meta.env.VITE_MAPKIT_TOKEN);
      },
    });
  }

  /**
   * Connect
   */
  connect() {
    useDebounce(this);
  }

  /**
   * Connect map.
   *
   * @param {HTMLElement} target
   */
  mapTargetConnected(target) {
    this.map = new mapkit.Map(target);
    this.map.cameraZoomRange = new mapkit.CameraZoomRange(250000, 20000000);
  }

  /**
   * Destroy map.
   */
  mapTargetDisconnected() {
    if (this.map !== undefined) {
      this.map.destroy();
    }
  }

  /**
   * Set markers for list.
   *
   * @param {HTMLElement} target
   */
  listTargetConnected(target) {
    const url = new URL(target.dataset.url);
    this.setMarkers(url);
  }

  /**
   * Set marker for dealer.
   *
   * @param {HTMLElement} target
   */
  dealerTargetConnected(target) {
    const url = new URL(target.dataset.url);
    this.setMarkers(url);
    this.queryTarget.value = '';
  }

  /**
   * Search.
   *
   * @param {Event} e
   */
  search(e) {
    const url = new URL(e.target.form.action);
    url.searchParams.set('q', e.target.value);
    Turbo.visit(url.toString(), { frame: 'dealers' });
  }

  /**
   * Geocode.
   */
  async geocode() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('hidden');
      this.loaderTarget.classList.add('flex');
    }

    // Get current GPS position
    try {
      const { coords } = await this.getPosition();

      // Send coords to server to retrieve current country
      const coordinates = new mapkit.Coordinate(coords.latitude, coords.longitude);
      const address = await this.getAddress(coordinates);

      // Set country in search field and search
      this.queryTarget.value = address?.results[0]?.country;
      this.search({ target: this.queryTarget });
    } finally {
      if (this.hasLoaderTarget) {
        this.loaderTarget.classList.remove('flex');
        this.loaderTarget.classList.add('hidden');
      }
    }
  }

  /**
   * Get position.
   *
   * @return {Promise}
   */
  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  /**
   * Get address from coordinates.
   *
   * @param {Coordinate} coordinates
   *
   * @return {Promise}
   */
  getAddress(coordinates) {
    const geocoder = new mapkit.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.reverseLookup(coordinates, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  /**
   * Render markers.
   *
   * @param {URL} url
   */
  async setMarkers(url) {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('hidden');
      this.loaderTarget.classList.add('flex');
    }

    // Get markers
    const response = await fetch(url);
    const dealers = await response.json();

    // Set markers
    this.map.removeItems(this.annotations);
    this.annotations = [];
    for (let i = 0; i < dealers.length; i += 1) {
      const dealer = dealers[i];
      const coords = new mapkit.Coordinate(dealer.latitude, dealer.longitude);
      const markerClass = 'map-marker';

      const annotation = new mapkit.Annotation(coords, this.getMarker.bind(this, markerClass), {
        data: { ...dealer },
        animates: false,
        selected: url.searchParams.has('id'),
      });

      if (this.hasActiveClass) {
        annotation.addEventListener('select', (e) => {
          this.annotations.forEach((a) => {
            a.element.classList.toggle(this.activeClass, a.data.id === e.target.data.id);
          });

          if (!url.searchParams.has('id')) {
            Turbo.visit(e.target.data.url, { frame: 'dealers' });
          }
        });
      }

      this.annotations.push(annotation);
    }
    this.map.showItems(this.annotations);

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('flex');
      this.loaderTarget.classList.add('hidden');
    }
  }

  /**
   * Create marker.
   *
   * @param {String} markerClass
   *
   * @return {Element}
   */
  getMarker(markerClass) {
    const element = document.createElement('div');
    element.className = markerClass;

    return element;
  }
}
